<template>
  <ui-transition>
    <div v-if="!isLoading">
      <div class="mb-6">
        <div
          v-if="uploading"
          class="rounded-xl w-full h-48 text-center flex justify-center items-center bg-level-3 bg-opacity-50 mb-2"
        >
          <font-awesome-icon :icon="['fas', 'circle-notch']" class="text-sub text-3xl" spin />
        </div>
        <img
          v-else-if="editedItem.thumbnail"
          :src="`/uploads/equipments/${editedItem.thumbnail}`"
          class="rounded-xl w-full h-48 object-cover shadow-xl mb-2"
        />
        <div
          v-else
          class="rounded-xl w-full h-48 text-center flex justify-center items-center bg-level-3 bg-opacity-50 mb-2"
        >
          <div class="text-sub text-opacity-50">
            画像未登録
          </div>
        </div>
        <div v-if="editedItem.thumbnail" class="grid grid-cols-2 gap-2">
          <ui-file-upload
            target="equipments"
            button-class="w-full text-sm"
            @upload-begin="uploading = true"
            @upload-ok="editedItem.thumbnail = $event"
            @upload-end="uploading = false"
          >
            画像を変更
          </ui-file-upload>
          <ui-button color="error" class="w-full text-sm" @click="editedItem.thumbnail = ''">
            画像を削除
          </ui-button>
        </div>
        <div v-else>
          <ui-file-upload
            target="equipments"
            class="w-full text-sm"
            @upload-begin="uploading = true"
            @upload-ok="editedItem.thumbnail = $event"
            @upload-end="uploading = false"
          >
            画像をアップロード
          </ui-file-upload>
        </div>
      </div>
      <ui-input v-model="editedItem.name" label="名称" class="mb-6" :required="true" />
      <ui-number-input v-model="editedItem.stock" label="在庫" :min="0" class="mb-6" />
      <ui-select
        v-model="editedItem.equipment_place_id"
        label="場所"
        :options="places"
        class="mb-6"
        :required="true"
      />
      <ui-select
        v-model="editedItem.equipment_category_id"
        label="タグ"
        :options="categories"
        class="mb-6"
      />
      <ui-input v-model="editedItem.purchase_url" label="購入先URL" class="mb-6" />
      <ui-textarea v-model="editedItem.comment" label="コメント" class="mb-6" />
      <ui-button
        color="primary"
        class="w-full my-2"
        :loading="updating"
        :disabled="disabled"
        @click="save"
      >
        保存
      </ui-button>
    </div>
  </ui-transition>
</template>

<script>
import EquipmentService from '../services/EquipmentService'

export default {
  name: 'EquipmentEdit',
  props: {
    id: {
      type: [String, Number],
      default: 'new',
    },
  },
  data: () => ({
    isLoading: true,
    uploading: false,
    updating: false,
    places: [],
    categories: [],
    editedItem: {
      name: '',
      stock: 1,
      thumbnail: '',
      equipment_place_id: -1,
      equipment_category_id: null,
      purchase_url: '',
      comment: '',
    },
  }),
  computed: {
    disabled() {
      return !this.editedItem.name || this.editedItem.equipment_place_id === -1
    },
  },
  async created() {
    this.fetch()
    const [places, categories] = await Promise.all([
      EquipmentService.getPlaces(),
      EquipmentService.getCategories(),
    ])
    this.categories = [
      { value: null, text: '未設定' },
      ...categories.map(category => ({ value: category.id, text: category.name })),
    ]
    this.places = places.map(place => ({ value: place.id, text: place.name }))
  },
  methods: {
    async fetch() {
      this.isLoading = true
      if (this.id === 'new') {
        this.$nextTick(() => {
          this.isLoading = false
        })
        return
      }
      const equipment = await EquipmentService.getOne(this.id)
      this.editedItem.name = equipment.name
      this.editedItem.stock = equipment.stock
      this.editedItem.thumbnail = equipment.thumbnail
      if (equipment.equipment_place) {
        this.editedItem.equipment_place_id = equipment.equipment_place.id
      }
      if (equipment.equipment_category) {
        this.editedItem.equipment_category_id = equipment.equipment_category.id
      } else {
        this.editedItem.equipment_category_id = null
      }
      this.editedItem.purchase_url = equipment.purchase_url
      this.editedItem.comment = equipment.comment
      this.isLoading = false
    },
    async save() {
      this.updating = true
      if (this.id === 'new') {
        await EquipmentService.create({ ...this.editedItem })
      } else {
        await EquipmentService.update(this.id, { ...this.editedItem })
      }
      this.updating = false
      this.$store.dispatch('notification/set', {
        message: '備品情報を保存しました！',
        type: 'success',
      })
      this.$router.back()
    },
  },
}
</script>
